import ReactDOM from "react-dom";
import Breadcrum from "./Breadcrum";
import * as Helper from "./Helper";
import React,{useState,useEffect} from 'react';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
// import {BrowserRouter as Router,Switch,Route} from 'react-router-dom';
import axios from 'axios';




export default function OrderGenerated() {

    const[requests,setRequests] = useState([]);
    
    useEffect(()=>{
            getData();
    },[])

    function getData()
    {
        axios.get(Helper.apiurl()+'get-all-requests')
            .then(response => {
                setRequests(response.data.response.detail);
            });
    }

    // console.log();

  return (
    <>
      <div id="main-content">
        <div className="container-fluid">
          <Breadcrum  pageLocation="Request Generated" />

          <div className="row clearfix">
    <div className="col-lg-12 col-md-12">
        <div className="card">
            <div className="header">
                <h2>Generated Link But Not Pay Yet</h2>
            </div>
            <div className="body">
                <div className="table-responsive">
                    <table className="table table-hover m-b-0">
                        <thead>
                            <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone</th>
                            <th scope="col">Pay Status</th>
                            <th scope="col">Created At</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                requests.map(lead=>{  
                                return(
                                    lead.payment_status == null &&
                                    <tr data-toggle="modal" data-target={`#detail-${lead.id}`}>
                                        <th><i className="fa fa-circle "></i></th>
                                        <td>{lead.fullname}</td>
                                        <td><span>{lead.email}</span></td>
                                        <td><span>{lead.phone}</span></td>
                                        <td>Progress</td>
                                        <td>{lead.created_at}</td>


                                    <div className="modal fade" id={`detail-${lead.id}`}  tabindex="-1" role="dialog">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h4 className="title" id="defaultModalLabel">Details</h4>
                                                </div>
                                                <div className="modal-body">
                                                    <ul>
                                                        <li> ID :  <a className="text-primary" target="_blank" href={"http://live.smartacademichelp.co.uk/pay-request/req"+lead.id}>{lead.id}</a>  </li>
                                                        <li> Full Name :  {lead.fullname} </li>
                                                        <li> Email :  {lead.email} </li>
                                                        <li> Contact # :  {lead.phone} </li>
                                                        <li> Pay Status :  Not Paid </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </tr>
                                    )
                                })
                            }
                            
                            
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>                
</div>

        </div>
      </div>
    </>
  );
}
