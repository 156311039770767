import React from "react";
import ReactDOM from "react-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Login from "./Auth/Login";
import { AuthContext, authInitialState, authReducer } from "./Auth/AuthContext";
import axios from "axios";

export default function Index() {
  const [state, dispatch] = React.useReducer(authReducer, authInitialState);

  // if (localStorage.getItem("token")) {
  //   axios
  //     .post(`/api/verify-user-token`, { token: localStorage.getItem("token") })
  //     .then((result) => {
  //       if (result.data.status == 404 || result.data.status == 405) {
  //         dispatch({
  //           type: "LOGOUT",
  //           payload: "",
  //         });
  //       }
  //     });
  // }

  console.log(state.isAuthenticated);

  if (state.isAuthenticated == true) {

    return (  
        <AuthContext.Provider
          value={{
            authState: state,
            authDispatch: dispatch,
          }}
        >
          <Router>
            <div id="wrapper">
              <Navbar />
              <Sidebar />
              <Footer />
            </div>
          </Router>
        </AuthContext.Provider>
      );
      
  } else {

        return(
            <AuthContext.Provider
          value={{
            authState: state,
            authDispatch: dispatch,
          }}
        >
          <Login />
         
        </AuthContext.Provider>

        );
      
  }
}
