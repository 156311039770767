import ReactDOM from "react-dom";
import Breadcrum from "./Breadcrum";
import * as Helper from "./Helper";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Swal from 'sweetalert2';
// import {BrowserRouter as Router,Switch,Route} from 'react-router-dom';
import axios from "axios";

export default function PaymentMethod() {
  const [gatewayList, setGatewayList] = useState([]);
  const [show, setShow] = useState(false);
  const[inputData,setInputData] = useState([]);
  const[disableButton,setDisableButton] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    axios.get(Helper.apiurl() + "get-gateways").then((response) => {
      setGatewayList(response.data.response.detail);
    });
  }

  const formchangeHandler = (e) => {

    setInputData({ ...inputData, [e.target.name]: e.target.value});  

  };


  const handleSubmit = (e) => {
    e.preventDefault();
    postForm();
  };

  
const postForm = (e) => {

  let formData = new FormData();

  formData.append('gateway_name', inputData.gateway_name);
  formData.append('stripe_key', inputData.stripe_key);
  formData.append('stripe_secret', inputData.stripe_secret);

  axios({
      method: 'post',
      url: Helper.apiurl()+'add-gateway',
      data: formData
  }).then(function (res) {
      setDisableButton(true);
      console.log('res', res);
     
      Swal.fire({
          title: 'Gateway Updated',
        });
     
  }).catch(function (err) {
      console.log('err', err);
  });

  
}

  // console.log();

  return (
    <>
      <div id="main-content">
        <div className="container-fluid">
          <Breadcrum pageLocation="Payment Gateways" />

          <div className="row clearfix">
            <div className="col-lg-12 col-md-12">
              <div className="card">
                <div className="header">
                  <h2>Payment Gateways</h2>
                </div>
                <div className="body">
                  <div className="table-responsive">
                    <table className="table table-hover m-b-0">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Name</th>
                          <th scope="col">Key</th>
                          <th scope="col">Secret</th>
                          <th scope="col">Created At</th>
                          <th scope="col">Edit</th>
                        </tr>
                      </thead>
                      <tbody>
                        {gatewayList.map((list) => {
                          return (
                            <tr>
                              <th>
                                <i className="fa fa-circle "></i>
                              </th>
                              <td>{list.gateway_name}</td>
                              <td>{list.stripe_key}</td>
                              <td>{list.stripe_secret}</td>
                              <td>{list.created_at}</td>
                              <td>
                                <button
                                  onClick={() => setShow(true)}
                                  className="btn btn-primary"
                                >
                                  Edit
                                </button>
                              </td>
                            </tr>
                          );
                        })}

                        <Modal
                          show={show}
                          onHide={() => setShow(false)}
                          dialogClassName="modal-90w"
                          aria-labelledby={`#detail`}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title id={`detail`}>
                              Edit Credentail
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <form  onSubmit={handleSubmit}  className="body">
                              <div className="form-row">
                                <div className="form-group col-12 d-flex">
                                  <label className="w-25 align-self-center m-0">Full Name</label>
                                  <input
                                    type="text"
                                    name="gateway_name"
                                    className="form-control w-75"
                                    onChange={formchangeHandler}
                                    value={inputData.gateway_name}
                                  />
                                </div>

                                <div className="form-group col-12 d-flex">
                                  <label className="w-25 align-self-center m-0">Stripe Key</label>
                                  <input
                                    type="text"
                                    name="stripe_key"
                                    className="form-control w-75"
                                    onChange={formchangeHandler}
                                    value={inputData.stripe_key}
                                  />
                                </div>

                                <div className="form-group col-12 d-flex">
                                  <label className="w-25 align-self-center m-0">Stripe Secret</label>
                                  <input
                                    type="text"
                                    name="stripe_secret"
                                    className="form-control w-75"
                                    onChange={formchangeHandler}
                                    value={inputData.stripe_secret}
                                  />
                                </div>


                                <div className="form-group col-12">
                                    <button className="btn btn-secondary w-100">Submit</button>
                                </div>

                              </div>
                            </form>
                          </Modal.Body>
                        </Modal>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
