import React from "react";
import ReactDOM from "react-dom";
import * as Helper from "../Helper";
// import {BrowserRouter as Router,Switch,Route} from 'react-router-dom';

export default function Earnings(props) {
  return (
    <>
      <div className="card overflowhidden number-chart">
        <div className="body">
          <div className="number">
            <h6>EARNINGS</h6>
            <span>${props.earnings}</span>
          </div>
          <small className="text-muted">19% compared to last week</small>
        </div>
        
      </div>
    </>
  );
}
