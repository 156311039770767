import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactDOM from "react-dom";
import Breadcrum from "./Breadcrum";
import * as Helper from "./Helper";
import Earnings from "./Dashboard/Earnings";
import Leads from "./Dashboard/Leads";
import Orders from "./Dashboard/Orders";
import PeddingOrders from "./Dashboard/PeddingOrders";
// import {BrowserRouter as Router,Switch,Route} from 'react-router-dom';

export default function Home() {


  const [totalRecord, setTotalRecord] = useState([]);
  const [apiLoaded, setApiLoaded] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    axios
      .get(Helper.apiurl() + "get-all-report")
      .then((response) => {
        setTotalRecord(response.data.response.detail);
        // console.log(response.data.response.detail);
        setApiLoaded(true);
      });
  }


  return (
    <>
      <div id="main-content">
        <div className="container-fluid">
          <Breadcrum />
          <div className="row clearfix">
            <div className="col-lg-3 col-md-6 col-sm-6">
              {apiLoaded && <Earnings earnings={totalRecord.Earning} />}

            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              {apiLoaded && <Leads leads={totalRecord.listing} />}
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              {apiLoaded && <Orders orders={totalRecord.Order} />}
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              {apiLoaded && <PeddingOrders pendingorders={totalRecord.PenddingOrders} />}
            </div>
          </div>

          {/* <div className="row clearfix">

            <div className="col-lg-6 col-md-12">
              <div className="card">
                <div className="header">
                  <h2>Top Products</h2>
                  <ul className="header-dropdown">
                    <li className="dropdown">
                      <a
                        href="javascript:void(0);"
                        className="dropdown-toggle"
                        data-toggle="dropdown"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                      ></a>
                      <ul className="dropdown-menu dropdown-menu-right">
                        <li>
                          <a href="javascript:void(0);">Action</a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">Another Action</a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">Something else</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div className="body">
                  <div id="chart-top-products" className="chartist"></div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="card">
                <div className="header">
                  <h2>Referrals</h2>
                  <ul className="header-dropdown">
                    <li className="dropdown">
                      <a
                        href="javascript:void(0);"
                        className="dropdown-toggle"
                        data-toggle="dropdown"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                      ></a>
                      <ul className="dropdown-menu dropdown-menu-right">
                        <li>
                          <a href="javascript:void(0);">Action</a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">Another Action</a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">Something else</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div className="body">
                  <ul className="list-unstyled list-referrals">
                    <li>
                      <p>
                        <span className="value">2301</span>
                        <span className="text-muted float-right">
                          visits from Facebook
                        </span>
                      </p>
                      <div className="progress progress-xs progress-transparent custom-color-blue">
                        <div
                          className="progress-bar"
                          data-transitiongoal="87"
                        ></div>
                      </div>
                    </li>
                    <li>
                      <p>
                        <span className="value">2107</span>
                        <span className="text-muted float-right">
                          visits from Twitter
                        </span>
                      </p>
                      <div className="progress progress-xs progress-transparent custom-color-purple">
                        <div
                          className="progress-bar"
                          data-transitiongoal="34"
                        ></div>
                      </div>
                    </li>
                    <li>
                      <p>
                        <span className="value">2308</span>
                        <span className="text-muted float-right">
                          visits from Search
                        </span>
                      </p>
                      <div className="progress progress-xs progress-transparent custom-color-yellow">
                        <div
                          className="progress-bar"
                          data-transitiongoal="54"
                        ></div>
                      </div>
                    </li>
                    <li>
                      <p>
                        <span className="value">1024</span>
                        <span className="text-muted float-right">
                          visits from Affiliates
                        </span>
                      </p>
                      <div className="progress progress-xs progress-transparent custom-color-green">
                        <div
                          className="progress-bar"
                          data-transitiongoal="67"
                        ></div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="card">
                <div className="header">
                  <h2>Total Revenue</h2>
                  <ul className="header-dropdown">
                    <li className="dropdown">
                      <a
                        href="javascript:void(0);"
                        className="dropdown-toggle"
                        data-toggle="dropdown"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                      ></a>
                      <ul className="dropdown-menu dropdown-menu-right">
                        <li>
                          <a href="javascript:void(0);">Action</a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">Another Action</a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">Something else</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div className="body text-center">
                  <h4 className="margin-0">Total Sale</h4>
                  <h6 className="m-b-20">2,45,124</h6>
                  <input
                    type="text"
                    className="knob"
                    value="63"
                    data-width="100"
                    data-height="100"
                    data-thickness="0.25"
                    data-angleArc="250"
                    data-angleoffset="-125"
                    data-fgColor="#212121"
                    readonly
                  />
                  <div
                    className="sparkline"
                    data-type="bar"
                    data-width="97%"
                    data-height="26px"
                    data-bar-Width="6"
                    data-bar-Spacing="6"
                    data-bar-Color="#7460ee"
                  >
                    2,5,4,8,3,9,1,5
                  </div>
                  <h6 className="p-b-15">Weekly Earnings</h6>
                  <div
                    className="sparkline"
                    data-type="bar"
                    data-width="97%"
                    data-height="26px"
                    data-bar-Width="2"
                    data-bar-Spacing="4"
                    data-bar-Color="#11a0f8"
                  >
                    3,1,5,4,7,8,2,3,1,4,6,5,4,4,2,3,1,5,4,7,8,2,3,1,4,6,5,4,4,2
                  </div>
                  <h6>Monthly Earnings</h6>
                </div>
              </div>
            </div>
          </div>
           */}
        </div>
      </div>
    </>
  );
}
