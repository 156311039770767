

export const apiurl = () => {
    // return 'http://127.0.0.1:8000/api/';

    return 'https://apis.smartacademichelp.com/api/';

}



export const baseurl = () => {
    // return 'http://localhost:3001';
    return 'https://backend.smartacademichelp.com/';

}

