import React, { useEffect, useState, Component } from 'react';
import Login from './Auth/Login';
import Home from './Home';
import Leads from './Leads';
import DoneLeads from './SentLeads';
import OrderRequest from './OrderRequest';
import OrderGenerated from './OrderGenerated';
import OrderList from './OrderList';
import OrderDetails from './OrderDetails';
import PaymentMethod from './PaymentMethod';
import PirceRequest from './PirceRequest';
import QuotePrice from './QuotePrice';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';

export default function Sidebar() {


    return (
      <>
        <div id="left-sidebar" className="sidebar">
          <div className="sidebar-scroll">
            <div className="user-account">
              <img
                src="/assets/img/user.png"
                className="rounded-circle user-photo"
                alt="User Profile Picture"
              />
              <div className="dropdown">
                <span>Welcome,</span>
                <a
                  href="javascript:void(0);"
                  className="dropdown-toggle user-name"
                  data-toggle="dropdown"
                >
                  <strong>Elvis Thomas</strong>
                </a>
                <ul className="dropdown-menu dropdown-menu-right account">
                  <li>
                    <a href="">
                      <i className="icon-user"></i>My Profile
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <i className="icon-envelope-open"></i>Messages
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">
                      <i className="icon-settings"></i>Settings
                    </a>
                  </li>
                  <li className="divider"></li>
                  <li>
                    <a href="">
                      <i className="icon-power"></i>Logout
                    </a>
                  </li>
                </ul>
              </div>
              <hr />
              {/* <ul className="row list-unstyled">
                <li className="col-4">
                  <small>Sales</small>
                  <h6>456</h6>
                </li>
                <li className="col-4">
                  <small>Order</small>
                  <h6>1350</h6>
                </li>
                <li className="col-4">
                  <small>Revenue</small>
                  <h6>$2.13B</h6>
                </li>
              </ul> */}
            </div>
           
            {/* <ul className="nav nav-tabs">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#menu">
                  Menu
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#Chat">
                  <i className="icon-book-open"></i>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#setting">
                  <i className="icon-settings"></i>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#question">
                  <i className="icon-question"></i>
                </a>
              </li>
            </ul> */}

            
            <div class="tab-content p-l-0 p-r-0">
              <div class="tab-pane active" id="menu">
                <nav id="left-sidebar-nav" class="sidebar-nav">
                  <ul id="main-menu" class="metismenu">
                    <li class="dashboard active">
                      <a href="/" class="">
                        <i class="icon-home"></i> <span>Dashboard</span>
                      </a>
                    </li>
                    <li class="dashboard">
                      <a className="has-arrow" href="/" class="">
                        <i class="icon-home"></i> <span>Leads</span>
                      </a>

                      <ul>
                        <li class="active">
                          <Link  to="/new-leads">New Leads</Link>
                          <Link  to="/done-leads">Done Leads</Link>
                          
                        </li>
                      </ul>

                    </li>

                    <li class="dashboard">
                      <a className="has-arrow" href="/" class="">
                        <i class="icon-home"></i> <span>Order Requests</span>
                      </a>

                      <ul>
                        <li class="active">
                          <Link  to="/request-generated">Requests</Link>
                        </li>

                        <li class="active">
                          <Link  to="/all-order-request">Price Requests</Link>
                        </li>

                        <li class="active">
                          <Link  to="/orders">Orders</Link>
                        </li>
                      </ul>

                    </li>

                    <li class="dashboard">
                      <Link className="has-arrow" to="/payment-credentail" class="">
                        <i class="icon-home"></i> <span>Payment Credentail</span>
                      </Link>

                    </li>


                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/new-leads" component={Leads} />
          <Route exact path="/done-leads" component={DoneLeads} />
          <Route exact path="/order-request/:lead_id" component={OrderRequest} />
          <Route exact path="/request-generated/" component={OrderGenerated} />
          <Route exact path="/orders" component={OrderList} />
          <Route exact path="/order-detail/:order_id" component={OrderDetails} />
          <Route exact path="/payment-credentail" component={PaymentMethod} />
          <Route exact path="/all-order-request" component={PirceRequest} />
          <Route exact path="/quote-price/:order_id" component={QuotePrice} />
                 
        </Switch>
      </>
    );
}

