import React, { useEffect, useState, Component } from 'react';
import Login from './Auth/Login';
import Home from './Home'
import Swal from 'sweetalert2';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import { AuthContext, authInitialState, authReducer } from './Auth/AuthContext';

export default function Navbar(props)
{

    const { authDispatch, authState } = React.useContext(AuthContext);


    const Logout = () => {
        authDispatch({
            type: "LOGOUT",
            payload: ''
        })

        Swal.fire({
            title: 'Logout successfully!',
            // confirmButtonColor: "#1ed0b1"
        });

        // if(authState.isAuthenticated == false && history.pathname == "/my-account"){
        //     history.push({
        //         pathname: "/"
        //     });
        // }

        // event.reset();
    }
    

    return (

        <>

<nav className="navbar navbar-fixed-top">
    <div className="container-fluid">
        <div className="navbar-btn">
            <button type="button" className="btn-toggle-offcanvas"><i className="lnr lnr-menu fa fa-bars"></i></button>
        </div>

        <div className="navbar-brand">
            <a href=""><img src="/assets/img/logo.svg" alt="Lucid Logo" className="img-responsive logo" /></a>                
        </div>
        
        <div className="navbar-right">
            <form id="navbar-search" className="navbar-form search-form">
                <input value="" className="form-control" placeholder="Search here..." type="text" />
                <button type="button" className="btn btn-default"><i className="icon-magnifier"></i></button>
            </form>                

            <div id="navbar-menu">
                <ul className="nav navbar-nav">
                    {/* <li className="d-none d-sm-inline-block d-md-none d-lg-inline-block">
                        <a href="" className="icon-menu"><i className="fa fa-folder-open-o"></i></a>
                    </li>
                    <li className="d-none d-sm-inline-block d-md-none d-lg-inline-block">
                        <a href="" className="icon-menu"><i className="icon-calendar"></i></a>
                    </li>
                    <li className="d-none d-sm-inline-block">
                        <a href="" className="icon-menu"><i className="icon-bubbles"></i></a>
                    </li> */}
                    {/* <li className="d-none d-sm-inline-block">
                        <a href="" className="icon-menu"><i className="icon-envelope"></i><span className="notification-dot"></span></a>
                    </li> */}
                    <li className="dropdown">
                        <a href="javascript:void(0);" className="dropdown-toggle icon-menu" data-toggle="dropdown">
                            <i className="icon-bell"></i>
                            <span className="notification-dot"></span>
                        </a>
                        {/* <ul className="dropdown-menu notifications">
                            <li className="header"><strong>You have 4 new Notifications</strong></li>
                            <li>
                                <a href="javascript:void(0);">
                                    <div className="media">
                                        <div className="media-left">
                                            <i className="icon-info text-warning"></i>
                                        </div>
                                        <div className="media-body">
                                            <p className="text">Campaign <strong>Holiday Sale</strong> is nearly reach budget limit.</p>
                                            <span className="timestamp">10:00 AM Today</span>
                                        </div>
                                    </div>
                                </a>
                            </li>                               
                            <li>
                                <a href="javascript:void(0);">
                                    <div className="media">
                                        <div className="media-left">
                                            <i className="icon-like text-success"></i>
                                        </div>
                                        <div className="media-body">
                                            <p className="text">Your New Campaign <strong>Holiday Sale</strong> is approved.</p>
                                            <span className="timestamp">11:30 AM Today</span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                             <li>
                                <a href="javascript:void(0);">
                                    <div className="media">
                                        <div className="media-left">
                                            <i className="icon-pie-chart text-info"></i>
                                        </div>
                                        <div className="media-body">
                                            <p className="text">Website visits from Twitter is 27% higher than last week.</p>
                                            <span className="timestamp">04:00 PM Today</span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <div className="media">
                                        <div className="media-left">
                                            <i className="icon-info text-danger"></i>
                                        </div>
                                        <div className="media-body">
                                            <p className="text">Error on website analytics configurations</p>
                                            <span className="timestamp">Yesterday</span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li className="footer"><a href="javascript:void(0);" className="more">See all notifications</a></li>
                        </ul> */}
                    </li>
                    {/* <li className="dropdown">
                        <a href="javascript:void(0);" className="dropdown-toggle icon-menu" data-toggle="dropdown"><i className="icon-equalizer"></i></a>
                        <ul className="dropdown-menu user-menu menu-icon">
                            <li className="menu-heading">ACCOUNT SETTINGS</li>
                            <li><a href="javascript:void(0);"><i className="icon-note"></i> <span>Basic</span></a></li>
                            <li><a href="javascript:void(0);"><i className="icon-equalizer"></i> <span>Preferences</span></a></li>
                            <li><a href="javascript:void(0);"><i className="icon-lock"></i> <span>Privacy</span></a></li>
                            <li><a href="javascript:void(0);"><i className="icon-bell"></i> <span>Notifications</span></a></li>
                            <li className="menu-heading">BILLING</li>
                            <li><a href="javascript:void(0);"><i className="icon-credit-card"></i> <span>Payments</span></a></li>
                            <li><a href="javascript:void(0);"><i className="icon-printer"></i> <span>Invoices</span></a></li>                                
                            <li><a href="javascript:void(0);"><i className="icon-refresh"></i> <span>Renewals</span></a></li>
                        </ul>
                    </li> */}
                    <li>
                        <a type="button" onClick={() => Logout()}  className="icon-menu"><i className="icon-login"></i></a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>

              
        </>
    );
}


