import ReactDOM from "react-dom";
import Breadcrum from "./Breadcrum";
import * as Helper from "./Helper";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
// import {BrowserRouter as Router,Switch,Route} from 'react-router-dom';
import axios from "axios";

export default function PirceRequest() {
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    axios.get(Helper.apiurl() + "get-pirce-requests").then((response) => {
      setRequests(response.data.response.detail);
    });
  }

  // console.log();

  return (
    <>
      <div id="main-content">
        <div className="container-fluid">
          <Breadcrum pageLocation="Request Generated" />

          <div className="row clearfix">
            <div className="col-lg-12 col-md-12">
              <div className="card">
                <div className="header">
                  <h2>Recents Listings</h2>
                </div>
                <div className="body">
                  <div className="table-responsive">
                    <table className="table table-hover m-b-0">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Phone</th>
                          <th scope="col">Created At</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {requests.map((lead) => {
                          return (
                            lead.payment_status == null && (
                              <tr>
                                <th>
                                  <i className="fa fa-circle "></i>
                                </th>
                                <td>{lead.fullname}</td>
                                <td>
                                  <span>{lead.email}</span>
                                </td>
                                <td>
                                  <span>{lead.phone}</span>
                                </td>
                                <td>{lead.created_at}</td>
                                <td><Link className="btn btn-primary" to={'/quote-price/'+lead.id}>Make Quotation</Link></td>
                              </tr>
                            )
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
