import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import {useState} from 'react';
import {useFormik} from 'formik';
import Swal from 'sweetalert2';
import { AuthContext } from './AuthContext';
import axios from 'axios';
import * as Helper from "../Helper";

export default function Login() {

   
    
    const initialState = {
        email: "",
        password: "",
        errorMessage: null
      };
      


    const [data, setData] = React.useState(initialState);
    const { authDispatch , authState } = React.useContext(AuthContext);
    const [apiError, setApiError] = useState([]);
    const [disabled,setDisabled] = useState(false);
    const [securityPopup,setSecurityPopup] = useState(false);
    const [verifyEmail, SetVerifyEmail] = useState({});
    const [emailError, SetEmailError] = useState({});
    const [invalid,setInvalid] = useState(false);


    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },

        // We've added a validate function
        validate() {
            const errors = {};
            // Add the touched to avoid the validator validating all fields at once
            if (formik.touched.email && !formik.values.email) {
                errors.email = "Required";
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formik.values.email)) {
                errors.email = "Invalid email address";
            }

            if (formik.touched.password && !formik.values.password) {
                errors.password = "Required";
            }

            return errors;
        },

        onSubmit: values => {
            axios.post(Helper.apiurl()+`login`, values)
                .then(result => {
                    if(result.data.status == 200){
                        authDispatch({
                            type: "LOGIN",
                            payload: result.data.response.detail
                        })
                        Swal.fire({
                            title: 'Login successfully!',
                          });

                    } else {
                        Swal.fire({
                            title: result.data.error.detail.pop(),
                          });
                        setApiError((apiError) => [
                            ...apiError,
                            result.data.error,
                          ]);

                    }
                });
        },
    });
    
    const changeEmail = e => {
        if(e.target.value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)) {
            SetEmailError({ ...emailError, ['errorEmail']: "Invalid Email Address" });
        } else if (e.target.value == '') {
            SetEmailError({ ...emailError, ['errorEmail']: "Email is required" });
        } else {
            SetEmailError({ ...emailError, ['errorEmail']: "" });
            SetVerifyEmail({ ...verifyEmail, ['email']: e.target.value });
        }
    }


    return (
        <div className="vertical-align-wrap">
        <div className="vertical-align-middle auth-main">
            <div className="auth-box">
                <div className="top">
                    <img src="/assets/img/logo-white.svg" alt="Lucid" />
                </div>
                <div className="card">
                    <div className="header">
                        <p className="lead">Login to your account</p>
                    </div>
                    <div className="body">
                        <form className="form-auth-small"onSubmit={formik.handleSubmit}>
                            <div className="form-group">
                                <label for="signin-email" className="control-label sr-only">Email</label>
                                <input type="name" className="form-control" id="email" name="email" onChange={formik.handleChange} placeholder="Email" />
                                {formik.errors.email ? <div className="error">{formik.errors.email}</div> : null}
                            </div>
                            <div className="form-group">
                                <label for="signin-password" className="control-label sr-only">Password</label>
                                <input type="password" className="form-control" id="password" name="password" placeholder="Password"
                                   onChange={formik.handleChange} />
                                {formik.errors.password ? <div className="error">{formik.errors.password}</div> : null}
                            </div>


                            <div className="form-group clearfix">
                                <label className="fancy-checkbox element-left">
                                    <input type="checkbox" />
                                    <span>Remember me</span>
                                </label>								
                            </div>
                            <button type="submit" className="btn btn-primary btn-lg btn-block">LOGIN</button>
                            {/* <div className="bottom">
                                <span className="helper-text m-b-10"><i className="fa fa-lock"></i> <a href="">Forgot password?</a></span>
                                <span>Don't have an account? <a href="">Register</a></span>
                            </div> */}
                        </form>
                    </div>
                </div>
            </div>
        </div>
       
    </div>
        
    );
}



