import ReactDOM from "react-dom";
import Breadcrum from "./Breadcrum";
import * as Helper from "./Helper";
import React,{useState,useEffect} from 'react';
// import {BrowserRouter as Router,Switch,Route} from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import Swal from 'sweetalert2';
import Moment from 'moment';
import { AuthContext, authInitialState, authReducer } from './Auth/AuthContext';
import Dropzone, { useDropzone } from 'react-dropzone';






export default function QuoatePrice(props) {

    const { authDispatch, authState } = React.useContext(AuthContext);
    const[quoateDetail,setQuoateDetail] = useState([]);
    const[isLoaded,setIsLoaded] = useState(false);
    const[inputData,setInputData] = useState([]);

    const[disableButton,setDisableButton] = useState(false);

    let orderID = props.match.params.order_id;
    
    useEffect(()=>{
        getData();
    },[])


function getData()
{
    axios.get(Helper.apiurl()+'price-request-detail/'+orderID)
        .then(response => {
            setQuoateDetail(response.data.response.detail);
            setIsLoaded(true);
        });
}




const formchangeHandler = (e) => {

  setInputData({ ...inputData, [e.target.name]: e.target.value});  

};



const handleSubmit = (e) => {
  e.preventDefault();

  postForm();
};



const postForm = (e) => {

    let formData = new FormData();

    formData.append('id', orderID);
    formData.append('price', inputData.price);
 
    axios({
        method: 'post',
        url: Helper.apiurl()+'update-cost',
        data: formData
    }).then(function (res) {
        setDisableButton(true);
        console.log('res', res);
       
        let id = res.data.response.detail.id;
        let link = 'http://live.smartacademichelp.co.uk/pay-request/req'+id;
       
        Swal.fire({
          title: 'Price Updated With Link  <br/> your Link is <a target="_Blank" href="'+link+'"> Copy Link</a> ',
          });

       
    }).catch(function (err) {
        console.log('err', err);
    });

    
}

    



if(isLoaded == false)
{
    return (<></>)
}
else
{
       
  return (
    <>
      <div id="main-content">
        <div className="container-fluid">
          <Breadcrum pageLocation="Quoat Request" />
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="row clearfix">
              <div className="col-lg-8 col-md-8">
                <div className="card">
            
                  <div className="body">
                    <div className="form-row">
                      <div className="form-group col-12">
                        <h5>Contact Information</h5>
                      </div>
                      <div className="form-group col-6">
                        <label>Full Name</label>
                        <input
                          type="text"
                          name="fullname"
                          disabled
                          value={quoateDetail.fullname}
                          className="form-control"
                        />
                      </div>
                      <div className="form-group col-6">
                        <label>Email</label>
                        <input
                          type="text"
                          name="fullname"
                          disabled
                          value={quoateDetail.email}
                          className="form-control"
                        />
                      </div>
                      <div className="form-group col-6">
                        <label>Phone</label>
                        <input
                          type="text"
                          name="fullname"
                          disabled
                          value={quoateDetail.phone}
                          className="form-control"
                        />
                      </div>

                      <div className="form-group col-6">
                        <label>Country</label>
                        <input
                          type="text"
                          name="fullname"
                          disabled
                          value={quoateDetail.country}
                          className="form-control"
                        />
                      </div>

                      <div className="form-group col-12">
                        <h5>Paper Information</h5>
                      </div>

            
                      <div className="form-group col-6">
                        <label>Paper Type</label>
                        <input
                          type="text"
                          name="fullname"
                          disabled
                          value={quoateDetail.paper_type}
                          className="form-control"
                        />
                      </div>
                      <div className="form-group col-6">
                        <label>Subject</label>
                        <input
                          type="text"
                          name="fullname"
                          disabled
                          value={quoateDetail.subjects}
                          className="form-control"
                        />
                      </div>

                      <div className="form-group col-6">
                        <label>Paper Insntructions</label>
                        <input
                          type="text"
                          name="fullname"
                          disabled
                          value={
                            quoateDetail.paper_instructions
                          }
                          className="form-control"
                        />
                      </div>

                      <div className="form-group col-6">
                        <label>Deadline</label>
                        <input
                          type="text"
                          name="fullname"
                          disabled
                          value={quoateDetail.deadline}
                          className="form-control"
                        />
                      </div>

                      <div className="form-group col-12">
                        <h5>Your Total Estimate</h5>
                      </div>

                      <div className="form-group col-6">
                        <label>Cost</label>
                        <input
                          type="text"
                          name="price"
                          onChange={formchangeHandler}
                          value={inputData.price}
                          className="form-control"
                        />
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card">
                  <div className="header">
                    <h2>Save</h2>
                  </div>
                  <div className="body">
                    {quoateDetail.is_status === 1 ? (
                      <button disabled className="btn btn-success mr-1">
                        Send Request
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-success mr-1"
                        disabled={disableButton ? "disabled" : ""}
                      >
                         Send Request
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
}
