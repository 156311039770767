import ReactDOM from "react-dom";
import Breadcrum from "./Breadcrum";
import * as Helper from "./Helper";
import React,{useState,useEffect} from 'react';
// import {BrowserRouter as Router,Switch,Route} from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import Swal from 'sweetalert2';
import Moment from 'moment';
import { AuthContext, authInitialState, authReducer } from './Auth/AuthContext';
import Dropzone, { useDropzone } from 'react-dropzone';

export default function OrderRequest(props) {

    const { authDispatch, authState } = React.useContext(AuthContext);
    const[leadsData,setLeadsData] = useState([]);
    const[leads,setLeads] = useState([]);
    const[selectAcadmics,setSelectAcadmics] = useState([]);
    const[selectPaperType,setSelectPaperType] = useState([]);
    const[selectSubjects,setSelectSubjects] = useState([]);
    const[selectDeadline,setSelectDeadline] = useState([]);
    const[countries,setCountries] = useState([]);
    const[selecctCountry,setSelectCountry] = useState([]);
    const[inputData,setInputData] = useState([]);
    const[attacments,setAttacments] = useState([]);
    const[saveData,setSaveData] = useState([]);

    const[disableButton,setDisableButton] = useState(false);

    


    const[formNameValidation,setFormNameValidation] = useState({});
    const[formEmailValidation,setFormEmailValidation] = useState({});
    const[formPhoneValidation,setFormPhoneValidation] = useState({});
    const[formCountryValidation,setFormCountryValidation] = useState({});
    const[formAcadmicValidation,setFormAcadmicValidation] = useState({});
    const[formSubjectValidation,setFormSubjectValidation] = useState({});
    const[formInstructionValidation,setFormInstructionValidation] = useState({});
    const[formPriceValidation,setFormPriceValidation] = useState({});
    const[formDeadlineValidation,setFormDeadlineValidation] = useState({});





    const academicsArray = [
        { id: 1, name: 'High school' },
        { id: 2, name: 'Undergraduate' },
        { id: 3, name: 'Master`s' },
      ];

    const paperTypeArray = [
        { id: 1, name: 'Addmission Eassy' },
        { id: 2, name: 'Article' },
        { id: 3, name: 'Assignment' },
      ];

    const SubjectsArray = [
    { id: 1, name: 'Communication' },
    { id: 2, name: 'Accounts' },
    { id: 3, name: 'Education' },
    ];


    // let nextDate = new Date();
    // const date = new Date(nextDate.setDate(nextDate.getDate() + 3));
    
// add a day

const timestamp = Date.now(); // This would be the timestamp you want to format
const time22 = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(timestamp);
// console.log(time22);


function NextDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }


// console.log('nexy',NextDays(Date().toLocaleString(),1));

    const deadLineArray = [
        { id: 1, name: '30', type : "Minutes" },
        { id: 2, name: '1', type : "Hour" },
        { id: 3, name: '2',  type : "Hours" },
        { id: 4, name: '1', type : "Day" },
        { id: 5, name: '2', type : "Days" },
        { id: 6, name: '3',  type : "Days" },
        ];


        const thumbsContainer = {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
        };
    
        const thumb = {
            display: 'inline-flex',
            borderRadius: 2,
            border: '1px solid #eaeaea',
            marginBottom: 8,
            marginRight: 8,
            width: 80,
            height: 80,
            padding: 3,
            boxSizing: 'border-box'
        };
    
        const thumbInner = {
            display: 'block',
            minWidth: 92,
            overflow: 'hidden'
        };
    
        const img = {
            display: 'block',
            width: 'auto',
            height: '100%'
        };


    
    useEffect(()=>{
            // getData();
            getCountries();
            
    },[])

 


    // function getData()
    // {
    //     axios.get(Helper.apiurl()+'api/all-listings')
    //         .then(response => {
    //             setLeads(response.data.response.detail);
    //         });
    // }
    function getCountries()
    {
        axios.get('https://countriesnow.space/api/v0.1/countries/iso')
            .then(response => {
                setCountries(response.data.data);
            });
    }


    // function setAcademic(e, academicId) {
    //     // 
    //     setSelectAcadmics({ ...selectAcadmics, ['acadmic_id']: academicId });
    //     // console.log('setAcademic',purposeId);
    // }
    


    function setPaperType(e)
    {
        setSelectPaperType({ ...selectPaperType, ['type']: e.name })
    }

    function setSubjects(e)
    {
        setSelectSubjects({ ...selectSubjects, ['subject']: e.name })
    }

    // function setDeadline(e,deadlineId)
    // {
    //     setSelectDeadline({ ...selectDeadline, ['deadline_id']: deadlineId })
    // }

    

    const changeHandlerForPhone = (e) => {
        setInputData({ ...inputData, ['phone']: e });
    }    


    function setCountry(e)
    {
        setSelectCountry({ ...selecctCountry, ['country']: e.name })
    }

    const formchangeHandler = (e) => {
        

        // setAttacments(e.target.files);
        

       
        // const[formPhoneValidation,setFormPhoneValidation] = useState({});
        // const[formCountryValidation,setFormCountryValidation] = useState({});
        // const[formAcadmicValidation,setFormAcadmicValidation] = useState({});
        // const[formSubjectValidation,setFormSubjectValidation] = useState({});
        // const[formInstructionValidation,setInstructionValidation] = useState({});
        // const[formPriceValidation,setFormPriceValidation] = useState({});
        // const[formDeadlineValidation,setFormDeadlineValidation] = useState({});


        const re = /^[0-9\b]+$/;
        
        setInputData({ ...inputData, [e.target.name]: e.target.value  });

    
        if(e.target.name == 'deadline')
        {
            
            let getDeadLineByID = deadLineArray.filter(type => {
            return type.id == e.target.value;
            
            });

            [getDeadLineByID] = getDeadLineByID;

            console.log(getDeadLineByID);

            setInputData({ ...inputData, 
                [e.target.name]: getDeadLineByID.name +" "+ getDeadLineByID.type,
                ['start_at']: Moment().format('YYYY-MM-DD,h:mm:ss'),
                ['end_at']: Moment().add(getDeadLineByID.name, getDeadLineByID.type).format('YYYY-MM-DD,h:mm:ss') 
            });

        }

        if (e.target.name == 'fullname') {
            setFormNameValidation({});
        }
        if (e.target.name == 'email') {
            setFormEmailValidation({});
        }
        if (e.target.name == 'phone') {
            setFormPhoneValidation({});
        }
        if (e.target.name == 'price') {
            setFormPriceValidation({});
        }
        if (e.target.name == 'desc') {
            setFormInstructionValidation({});
        }        

    }

    

    // console.log('get',inputData);


    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        maxFiles: 10,
        onDrop: acceptedFiles => {
            setAttacments(acceptedFiles.map((file, index) => Object.assign(file, {
                preview: URL.createObjectURL(file),
            })));
        }
    });

    const thumbs = attacments.map((attacment, index) => (
        <div>
            <div style={thumb} className="default-image" key={attacment.name}>
                <div style={thumbInner}>
                    <img
                        src={attacment.preview}
                        style={img}
                    />
                </div>
            </div>
    
        </div>
    ));



    const saveImage = () => {
        attacments.forEach(file => URL.revokeObjectURL(file.preview));
        setInputData({ ...inputData, ['attacment']: attacments });
        // if (files.length != 0) {
        //     setPropertyImageError({});
        // }
    }


    
    useEffect(() => {
        saveImage();
    }, [attacments]);



    const handleSubmit = (e) => 
    {
        e.preventDefault();
         
        if (!inputData.hasOwnProperty('fullname') || inputData.fullname == "") {
            setFormNameValidation({ ...formNameValidation, ['fullname']: ' Name is Required' });
        }

        if (!inputData.hasOwnProperty('email') || inputData.email == "") {
            setFormEmailValidation({ ...formEmailValidation, ['email']: ' Email is Required' });
        }

        // if (!inputData.hasOwnProperty('phone') || inputData.phone == "") {
        if (!inputData.hasOwnProperty('phone') || inputData.phone == "" || (inputData.phone.substring(0, 2) == "92" && inputData.phone.length <= 11)) {
            
            setFormPhoneValidation({ ...formPhoneValidation, ['phone']: 'Contact Number is Required' });
        }

        if (!inputData.hasOwnProperty('price') || inputData.price == "") {
            setFormPriceValidation({ ...formPriceValidation, ['price']: 'Price is Required' });
        }

        if (!inputData.hasOwnProperty('desc') || inputData.desc == "") {
            setFormInstructionValidation({ ...formInstructionValidation, ['desc']: 'Paper Instructions is Required' });
        }

        

        if (
            inputData.hasOwnProperty('fullname') && inputData.fullname != "" &&
            inputData.hasOwnProperty('email') && inputData.email != "" &&
            inputData.hasOwnProperty('phone') && inputData.phone != "" &&
            inputData.hasOwnProperty('price') && inputData.price != "" &&
            inputData.hasOwnProperty('desc') && inputData.desc != "" 
           ) {
            
                // setDisableButton(true);
               
                
                postForm();
                
          
        }


        

    }
    
    // console.log(Date().toLocaleString() );
    // console.log('test',new Date(Date.now()).toISOString().slice(0, 19).replace('T', ' '));

    // console.log('Moment',Moment().add(2, 'Days').format('YYYY-MM-DD,h:mm:ss'));

    // console.log(inputData.deadline);



    const postForm = (e) => {

        console.log('innn',inputData);
        let formData = new FormData();

        formData.append('paper_type', selectPaperType.type);
        formData.append('subjects', selectSubjects.subject);
        formData.append('academic_level', inputData.academic_level);
        formData.append('deadline', inputData.deadline);
        formData.append('start_at', inputData.start_at);
        formData.append('end_at', inputData.end_at);
        formData.append('price', inputData.price);
        formData.append('fullname', inputData.fullname);
        formData.append('email', inputData.email);
        formData.append('phone', inputData.phone);
        formData.append('paper_instructions', inputData.desc);
        formData.append('country', selecctCountry.country);
        

        
        

        formData.append('user_id', authState.user.id);
        formData.append('listing_id', props.match.params.lead_id);

        for (var key in inputData.attacment) {
            formData.append('attacments[]', inputData.attacment[key])
        }
        

        axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
        axios({
            method: 'post',
            url: Helper.apiurl()+'order-generate',
            data: formData
        }).then(function (res) {
            setDisableButton(false);
            console.log('res', res);
            let id = res.data.response.detail.Generate_request.id;
            let link = Helper.baseurl+'/pay-request/req'+id;
           
            Swal.fire({
                title: 'Order Genderated  <br/> your Link is <a target="_Blank" href="'+link+'"> Copy Link</a> ',
              });
           
        }).catch(function (err) {
            console.log('err', err);
        });

        
    }
    
        

    
    // console.log('attacments',attacments);

    // console.log('selectAcadmics',selectAcadmics.acadmic_id);
    // console.log('selectPaperType',selectPaperType);
    // console.log('selectPaperType',selectSubjects);
    // console.log('selectDeadline',selectDeadline.deadline_id);
    // console.log('inputData',inputData);

    

  return (
    <>
      <div id="main-content">
        <div className="container-fluid">
          <Breadcrum />
          <form  onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="row clearfix">
                <div className="col-lg-8 col-md-8">
                    <div className="card">
                        <div className="header">
                            <h2>Request For Order</h2>
                        </div>

                        <div  className="body">


                        <div className="form-row">
                            <div className="form-group col-6">
                                <label>Full Name</label>
                                <input type="text" name="fullname" onChange={formchangeHandler} className="form-control" />

                                {formNameValidation.hasOwnProperty('fullname') ? <div className="req-val" >{formNameValidation.fullname}</div> : null}
                            </div>
                            <div className="form-group col-6">
                                <label>Email</label>
                                <input type="email" name="email" onChange={formchangeHandler}  className="form-control" />
                                {formEmailValidation.hasOwnProperty('email') ? <div className="req-val" >{formEmailValidation.email}</div> : null}
                            </div>


                            <div className="form-group col-6">
                            <label>Phone / Whatsapp</label>

                                <PhoneInput
                                className="form-control w-100"
                                name="phone"
                                placeholder="Phone number"
                                country={'uk'}
                                onChange={(e) => changeHandlerForPhone(e)} />

                        {formPhoneValidation.hasOwnProperty('phone') ? <div className="req-val" >{formPhoneValidation.phone}</div> : null}
                            </div>

                            <div className="form-group col-6">
                            <label>Select Country</label>

                            <Select placeholder="Select Country" options={countries}  getOptionValue={option => option['Iso3']} getOptionLabel={option => `${option.name}`}  onChange={e => { setCountry(e) }} className="react-select" />
                            {formCountryValidation.hasOwnProperty('phone') ? <div className="req-val" >{formCountryValidation.country}</div> : null}
                            </div>

                        </div>

                        <div className="form-group">
                            <label>Select Acadmics </label>
            
                            <ul className="nav nav-pills nav-pills1 search-tabs " >
                                {
                                    academicsArray.map(academic => {
                                        return (
                                            <li className="nav-item" key={academic.id}>
                                                <label className="nav-link"  value={academic.id} >
                                                <input type="radio" value={academic.id} onChange={formchangeHandler} name="academic_level" /> 
                                                    {academic.name}
                                                </label>
                                            </li>  
                                        )
                                    })


                                }
                            </ul>                    
                            
                        </div>


                        <div className="form-row">
                            <div className="form-group col-6">
                                <label>Select Paper Type </label>
                                <Select placeholder="Select Paper Types" options={paperTypeArray}  getOptionValue={option => option['id']} getOptionLabel={option => `${option.name}`}  onChange={e => { setPaperType(e) }} className="react-select" />
                            </div>


                            <div className="form-group col-6">
                                <label>Select Subjects </label>
                                <Select placeholder="Select Paper Types" options={SubjectsArray}  getOptionValue={option => option['id']} getOptionLabel={option => `${option.name}`}  onChange={e => { setSubjects(e) }} className="react-select" />
                            </div>
                        </div>


                        

                        <div className="form-group">
                            <label>Paper Instruction</label>
                            <textarea placeholder="Instruction" onChange={formchangeHandler} name="desc" className="form-control"  ></textarea>
                            {formInstructionValidation.hasOwnProperty('desc') ? <div className="req-val" >{formInstructionValidation.desc}</div> : null}
                        </div>

                        <div className="form-group">
                            <label>Price</label>
                            <input type="text"  name="price" onChange={formchangeHandler}  placeholder="Price" className="form-control"  />
                            
                            {formPriceValidation.hasOwnProperty('price') ? <div className="req-val" >{formPriceValidation.price}</div> : null}
                        </div>

                        
                        <div className="form-group">
                            <label>Deadline</label>
                            <ul className="nav nav-pills nav-pills1 search-tabs " >
                            {
                                deadLineArray.map(deadline => {
                                    return (
                                     

                                    <li className="nav-item" key={deadline.id}>
                                        <label className="nav-link"  value={deadline.id}  >
                                        <input type="radio" value={deadline.id } onChange={formchangeHandler} name="deadline" /> 
                                        {deadline.name + " " + deadline.type }
                                        </label>
                                    </li>  

                                        
                                    )
                                })
                            }
                        </ul>
                        </div>

                    
                
                    </div>
                    </div>
                </div>    
                
                <div className="col-md-4">
                <div className="card">
                        <div className="header">
                            <h2>Save</h2>
                        </div>
                        <div  className="body">
                    

                        {/* <div className="form-group">
                            <label>Attacments </label>
                            <input
                                type="file"
                                name="attacments[]"
                                label="Product Picture"
                                onChange={formchangeHandler}
                                className="form-control" 
                                multiple
                            />
                        </div> */}


                        <section className=" text-center">
                                            <aside style={thumbsContainer}>
                                                {
                                                
                                                }
                                                {thumbs}
                                            </aside>
                                            <div {...getRootProps({ className: 'dropzone' })}>
                                                <input {...getInputProps()} />
                                                <hr />
                                                <div className="m-0 m-auto">Upload Attachments</div>
                                                <p>or drag them in (max size: 5mb)</p>
                                            </div>
                                        </section>

                        <button type="submit"  className="btn btn-primary" disabled={(disableButton) ? 'disabled' : ''}>Save And Generate Link</button>
                    
                    </div>
                    </div>
                </div>            
            </div>
          </form>

        </div>
      </div>
    </>
  );
}
