import ReactDOM from "react-dom";
import Breadcrum from "./Breadcrum";
import * as Helper from "./Helper";
import React,{useState,useEffect} from 'react';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal'
// import {BrowserRouter as Router,Switch,Route} from 'react-router-dom';
import axios from 'axios';




export default function Leads() {

    const[leads,setLeads] = useState([]);
    const [show, setShow] = useState(false);
    
    
    useEffect(()=>{
            getData();
    },[])

    function getData()
    {
        axios.get(Helper.apiurl()+'all-listings')
            .then(response => {
                setLeads(response.data.response.detail);
            });
    }

    // console.log();

  return (
    <>
      <div id="main-content">
        <div className="container-fluid">
          <Breadcrum pageLocation="New Listings" />

          <div className="row clearfix">
            <div className="col-lg-12 col-md-12">
              <div className="card">
                <div className="header">
                  <h2>Recents Listings</h2>
                </div>
                <div className="body">
                  <div className="table-responsive">
                    <table className="table table-hover m-b-0">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Phone</th>
                          <th scope="col">Status</th>
                          <th scope="col">Created At</th>
                        </tr>
                      </thead>
                      <tbody>
                        {leads.map((lead) => {
                          return (
                            lead.status == "Not Send" && (
                              <tr data-toggle="modal" data-target={`#detail-${lead.id}`}>
                            {/* //   <tr onClick={() => setShow(true)}> */}
                                <th>
                                  <i className="fa fa-circle "></i>
                                </th>
                                <td>{lead.fullname}</td>
                                <td>
                                  <span>{lead.email}</span>
                                </td>
                                <td>
                                  <span>{lead.phone}</span>
                                </td>
                                <td>{lead.status}</td>
                                <td>{lead.created_at}</td>

                            

                                <div className="modal fade" id={`detail-${lead.id}`}  tabindex="-1" role="dialog">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h4 className="title" id="defaultModalLabel">Details</h4>
                                                </div>
                                                <div className="modal-body">
                                                    <ul>
                                                        <li> Full Name :  {lead.fullname} </li>
                                                        <li> Email :  {lead.email} </li>
                                                        <li> Contact # :  {lead.phone} </li>
                                                        <li> Message :  {lead.desc} </li>
                                                    </ul>

                                                    <a href={`https://api.whatsapp.com/send?phone=+${lead.phone}&text=${`Message:Hi ${lead.fullname}, Thank you We will Contact you Soon as possible`}`}  target="_blank" className="btn btn-primary">Get Details</a>
                                                    {/* <Link  to={'/order-request/' + lead.id} className="btn btn-secondary"> Make Payment Form</Link> */}
                                                    <a href={'/order-request/' + lead.id} className="btn btn-secondary">Make Payment Form</a>
                                                    
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-danger" data-dismiss="modal">CLOSE</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                              </tr>
                            )
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
