import ReactDOM from "react-dom";
import Breadcrum from "./Breadcrum";
import * as Helper from "./Helper";
import React,{useState,useEffect} from 'react';
// import {BrowserRouter as Router,Switch,Route} from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import Swal from 'sweetalert2';
import Moment from 'moment';
import { AuthContext, authInitialState, authReducer } from './Auth/AuthContext';
import Dropzone, { useDropzone } from 'react-dropzone';
import Countdown from "react-countdown";

const Completionist = () => <span >Order Completed Check Email</span>;

// Renderer callback with condition
const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <span>
        {hours}:{minutes}:{seconds}
      </span>
    );
  }
};


export default function OrderDetails(props) {

    const { authDispatch, authState } = React.useContext(AuthContext);
    const[orderDetail,setOrderDetail] = useState([]);
    const[isLoaded,setIsLoaded] = useState(false);
    const[inputData,setInputData] = useState([]);

    const[disableButton,setDisableButton] = useState(false);

    let orderID = props.match.params.order_id;
    
    useEffect(()=>{
        getData();
    },[])


function getData()
{
    axios.get(Helper.apiurl()+'order-detail/'+orderID)
        .then(response => {
            setOrderDetail(response.data.response.detail);
            setIsLoaded(true);
        });
}




const formchangeHandler = (e) => {

  setInputData({ ...inputData, [e.target.name]: e.target.value});  

};



const handleSubmit = (e) => {
  e.preventDefault();

  postForm();
};



const postForm = (e) => {

    let formData = new FormData();

    formData.append('orderNote', inputData.order_note);
    formData.append('orderCode', orderID);
    formData.append('orderID', orderDetail.id);
    formData.append('compeleted_at', Moment().format("YYYY-MM-DD,h:mm:ss"));
 
    axios({
        method: 'post',
        url: Helper.apiurl()+'order-complete',
        data: formData
    }).then(function (res) {
        setDisableButton(true);
        console.log('res', res);
       
        Swal.fire({
            title: 'Order is Completed ',
          });
       
    }).catch(function (err) {
        console.log('err', err);
    });

    
}

    



if(isLoaded == false)
{
    return (<></>)
}
else
{

var startTime = Date.parse(orderDetail.more_order_detail.start_at);
var endTime = Date.parse(orderDetail.more_order_detail.end_at);
       
  return (
    <>
      <div id="main-content">
        <div className="container-fluid">
          <Breadcrum pageLocation="Orders Details" />
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="row clearfix">
              <div className="col-lg-8 col-md-8">
                <div className="card">
                  <div className="header">
                    <h2>Order #{orderDetail.order_code} Details</h2>
                    <p>
                      {Moment(orderDetail.created_at).format(
                        "dddd, MMMM Do, YYYY @ h:mm A"
                      )}{" "}
                      . Customer IP: {orderDetail.ip}
                    </p>
                  </div>

                  <div className="body">
                    <div className="form-row">
                      <div className="form-group col-12">
                        <h5>Order Details</h5>
                      </div>
                      <div className="form-group col-4">
                        <label>Full Name</label>
                        <input
                          type="text"
                          name="fullname"
                          disabled
                          value={orderDetail.more_order_detail.fullname}
                          className="form-control"
                        />
                      </div>
                      <div className="form-group col-4">
                        <label>Email</label>
                        <input
                          type="text"
                          name="fullname"
                          disabled
                          value={orderDetail.more_order_detail.email}
                          className="form-control"
                        />
                      </div>
                      <div className="form-group col-4">
                        <label>Phone</label>
                        <input
                          type="text"
                          name="fullname"
                          disabled
                          value={orderDetail.more_order_detail.phone}
                          className="form-control"
                        />
                      </div>

                      <div className="form-group col-4">
                        <label>Country</label>
                        <input
                          type="text"
                          name="fullname"
                          disabled
                          value={orderDetail.more_order_detail.country}
                          className="form-control"
                        />
                      </div>

                      <div className="form-group col-12">
                        <h5>Product Information</h5>
                      </div>

                      <div className="form-group col-4">
                        <label>Deadline</label>
                        <input
                          type="text"
                          name="fullname"
                          disabled
                          value={orderDetail.more_order_detail.deadline}
                          className="form-control"
                        />
                      </div>

                      <div className="form-group col-4">
                        <label>Total</label>
                        <input
                          type="text"
                          name="fullname"
                          disabled
                          value={"$" + orderDetail.price}
                          className="form-control"
                        />
                      </div>

                      <div className="form-group col-4">
                        <label>Progress Status</label>
                        <select className="form-control">
                          <option value="In Progress">In Progress</option>
                        </select>
                      </div>

                      <div className="form-group col-4">
                        <label>Paper Type</label>
                        <input
                          type="text"
                          name="fullname"
                          disabled
                          value={orderDetail.more_order_detail.paper_type}
                          className="form-control"
                        />
                      </div>
                      <div className="form-group col-4">
                        <label>Subject</label>
                        <input
                          type="text"
                          name="fullname"
                          disabled
                          value={orderDetail.more_order_detail.subjects}
                          className="form-control"
                        />
                      </div>

                      <div className="form-group col-4">
                        <label>Paper Insntructions</label>
                        <input
                          type="text"
                          name="fullname"
                          disabled
                          value={
                            orderDetail.more_order_detail.paper_instructions
                          }
                          className="form-control"
                        />
                      </div>

                      <div className="form-group col-6">
                        <label>Deadline</label>
                        <input
                          type="text"
                          name="fullname"
                          disabled
                          value={orderDetail.more_order_detail.deadline}
                          className="form-control"
                        />
                      </div>

                      <div className="form-group col-6">
                        <label>Time Left</label>
                        <p
                          className="form-control bg-success"
                          style={{ color: "white" }}
                        >
                          <Countdown date={(startTime, endTime)}>
                            <Completionist />
                          </Countdown>
                        </p>
                      </div>

                      <div className="form-group col-12">
                        <label>Note:</label>
                        <textarea
                          name="order_note"
                          value={inputData.order_note}
                          className="form-control w-100"
                          onChange={formchangeHandler}
                        >
                          {inputData.order_note}
                        </textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card">
                  <div className="header">
                    <h2>Save</h2>
                  </div>
                  <div className="body">
                    {orderDetail.is_status === 1 ? (
                      <button disabled className="btn btn-success mr-1">
                        Completed
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-success mr-1"
                        disabled={disableButton ? "disabled" : ""}
                      >
                        Complete
                      </button>
                    )}

                    {/* <button type="submit"  className="btn btn-danger mr-1" disabled={(disableButton) ? 'disabled' : ''}>Cancel</button> */}
                    <button
                      type="submit"
                      className="btn btn-danger mr-1"
                    disabled
                    >
                      Refund
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
}
