import ReactDOM from "react-dom";
import Breadcrum from "./Breadcrum";
import * as Helper from "./Helper";
import React,{useState,useEffect} from 'react';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
// import {BrowserRouter as Router,Switch,Route} from 'react-router-dom';
import axios from 'axios';




export default function OrderList() {

    const[requests,setRequests] = useState([]);
    
    useEffect(()=>{
            getData();
    },[])

    function getData()
    {
        axios.get(Helper.apiurl()+'get-all-requests')
            .then(response => {
                setRequests(response.data.response.detail);
            });
    }

    // console.log();

  return (
    <>
      <div id="main-content">
        <div className="container-fluid">
          <Breadcrum  pageLocation="Orders" />

          <div className="row clearfix">
    <div className="col-lg-12 col-md-12">
        <div className="card">
            <div className="header">
                <h2>Orders</h2>
            </div>
            <div className="body">
                <div className="table-responsive">
                    <table className="table table-hover m-b-0">
                        <thead>
                            <tr>
                            <th scope="col">#</th>
                            <th scope="col">Order ID</th>
                            <th scope="col">Pay Status</th>
                            <th scope="col">Cost</th>
                            <th scope="col">Progress Status</th>
                            <th scope="col">Deadline</th>
                            <th scope="col">Created At</th>
                            <th scope="col">Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                requests.map(lead=>{  
                                return(
                                    lead.payment_status == 1 &&
                                    <tr>
                                        <th><i className="fa fa-circle "></i></th>
                                        <td>#{lead.track_id}</td>
                                        <td>Paid</td>
                                        <td>${lead.price}</td>
                                        <td> <span  className={lead.progress_status === 'Completed' ? "btn btn-success" : "btn btn-secondary"}>{lead.progress_status}</span> </td>
                                        <td>{lead.deadline}</td>
                                        <td>{lead.created_at}</td>
                                        <td><Link className="btn btn-primary" to={'/order-detail/'+lead.track_id} >Details</Link></td>
                                    </tr>
                                    )
                                })
                            }
                            
                            
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>                
</div>

        </div>
      </div>
    </>
  );
}
