import React from 'react';
import ReactDOM from 'react-dom';
// import {BrowserRouter as Router,Switch,Route} from 'react-router-dom';



export default function Breadcrum(props) {

    return (
        <>
                            <div className="block-header">
                        <div className="row">
                            <div className="col-lg-5 col-md-8 col-sm-12">                        
                                <h2><a href="javascript:void(0);" className="btn btn-xs btn-link btn-toggle-fullwidth"><i className="fa fa-arrow-left"></i></a>Dashborad </h2>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="{{route('dashboard.analytical')}}"><i className="icon-home"></i></a></li>
                                   
                                       <li className="breadcrumb-item">Dashborad</li>
                                   
                                        <li className="breadcrumb-item active">{props.pageLocation}</li>
                                   
                                </ul>
                            </div>            
                            <div className="col-lg-7 col-md-4 col-sm-12 text-right">
                                <div className="inlineblock text-center m-r-15 m-l-15 hidden-sm">
                                    <div className="sparkline text-left" data-type="line" data-width="8em" data-height="20px" data-line-Width="1" data-line-Color="#00c5dc"
                                        data-fill-Color="transparent">3,5,1,6,5,4,8,3</div>
                                    <span>Visitors</span>
                                </div>
                                <div className="inlineblock text-center m-r-15 m-l-15 hidden-sm">
                                    <div className="sparkline text-left" data-type="line" data-width="8em" data-height="20px" data-line-Width="1" data-line-Color="#f4516c"
                                        data-fill-Color="transparent">4,6,3,2,5,6,5,4</div>
                                    <span>Visits</span>
                                </div>
                            </div>
                        </div>
                    </div>
        </>
    );
}

