import React from 'react';
import { useHistory } from "react-router";
import  { Redirect } from 'react-router-dom'

export const AuthContext = React.createContext();

export const authInitialState = {
  isAuthenticated: localStorage.getItem("user") ? true : false,
  isAgent: localStorage.getItem("user") ? true : false,
  user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null,
  token: localStorage.getItem("token") ? localStorage.getItem("token") : null,
};

export const authReducer = (state, action,props) => {
  
    switch (action.type) {
      
      case "LOGIN":
        localStorage.setItem("user", JSON.stringify(action.payload.user));
        localStorage.setItem("token", JSON.stringify(action.payload.access_token));
        return {
          ...state,
          isAuthenticated: true,
          isAgent: action.payload.isAgent,
          user: action.payload.user,
          token: action.payload.access_token
        };
        case "REGISTER":
          localStorage.setItem("user", JSON.stringify(action.payload.user));
          localStorage.setItem("token", JSON.stringify(action.payload.access_token));
        return {
          ...state,
          isAuthenticated: true,
          isAgent: action.payload.isAgent,
          user: action.payload.user,
          token: action.payload.access_token
        };
        case "UPDATE_PROFILE":
          localStorage.setItem("user", JSON.stringify(action.payload));
          localStorage.setItem("token", localStorage.getItem("token"));
        return {
          ...state,
          isAuthenticated: true,
          isAgent: action.payload.isAgent,
          user: action.payload,
          token: localStorage.getItem("token")
        };
        case "LOGOUT":
        
        
        localStorage.clear();  
          
          if(!localStorage.getItem('user') && (window.location.pathname == "/my-account" || window.location.pathname == "/convert-to-agency")){
              window.location.href = "/";
          };         
          return {
            ...state,
            isAuthenticated: false,
            isAgent: null,
            user: null,
            token: null
          };          
        default:
        return state;
    }
  };


